var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('TButton',{staticClass:"btn-danger btn-sm btn-ghost-danger",attrs:{"content":"Watch boxes was not in container"},on:{"click":_vm.openModalShowBoxNotInContainer}}),_c('TModal',{attrs:{"title":"Watch boxes was not in container","size":"lg","show":_vm.show,"fetching":_vm.loading},on:{"update:show":function($event){_vm.show=$event}},scopedSlots:_vm._u([{key:"actions",fn:function(){return [(!!_vm.data_table.length)?_c('TButtonSave',{attrs:{"options":{ content: 'Export CSV' }},on:{"click":_vm.onExportCSV}}):_vm._e(),_c('TButton',{staticClass:"btn-danger",attrs:{"content":"Remove all","disabled":_vm.loading},on:{"click":_vm.onRemoveAll}})]},proxy:true}])},[_c('TTableSimple',{attrs:{"fields":_vm.referenceFields,"items":_vm.data_table,"noResult":""},scopedSlots:_vm._u([{key:"_",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.pivot_lading_bills_with_container_count == 1)?_c('b',{staticClass:"text-success",staticStyle:{"font-size":"12px"}},[_vm._v("1 "+_vm._s(_vm.$t("Lading bill")))]):_c('b',{staticClass:"text-danger",staticStyle:{"font-size":"12px"}},[_vm._v(_vm._s(item.pivot_lading_bills_with_container_count)+" "+_vm._s(_vm.$t("Lading bill")))])])]}},{key:"box_id",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('b',[_vm._v(_vm._s(item.box_id))])])]}},{key:"lading_bill_id",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('p',[_vm._v(_vm._s(item.lading_bill_id))])])]}},{key:"quantity",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessageNumber',{attrs:{"value":item.quantity,"editable":item.pivot_lading_bills_with_container_count > 1},on:{"change":function (q) { return _vm.onChangeQuantity(q, item); }}}),(item.pivot_lading_bills_with_container_count > 1)?_c('div',{staticClass:"d-flex align-items-center",staticStyle:{"font-size":"10px"}},[_c('TMessage',{attrs:{"content":"Quantity in lading bill"}}),_c('b',{staticClass:"ml-1"},[_vm._v(_vm._s(item.box_lading_bill_quantity))])],1):_vm._e()],1)]}},{key:"updated_at",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessageDateTime',{attrs:{"content":item.updated_at}})],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }